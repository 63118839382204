.App {
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 30px 0;
}
.button {
    z-index: 5;
    min-width: 326px;
    cursor: pointer;
    font-family: "Press Start 2P";
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 28px;
    -webkit-text-stroke-width: 0.1rem;
    -webkit-text-stroke-color: #000000;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    color: #FFFFFF;
    width: auto;
    padding: 20px;
    background: #F8E038;
    border-radius: 30px;
}

.text {
    text-align: center;
    margin-bottom: 20px;
    font-size: 30px;
}